@import '../../variables.scss';

.footer {
  background-color: black;
  color: white;
  padding: 40px calc(100vw*(170px / 1920px));;
  font-size: 12px;
  font-family: 'freight-sans-pro,sans-serif';
  text-align: left;

  .footer-content {
    display: flex;
    line-height: 18px;

    .column {
      flex: 1;
      padding-right: .9375rem;
      padding-left: .9375rem;

      h5 {
        margin-bottom: 10px;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin: 5px 0;
        }
      }
    }

    .right-column {
      border-left: 1px solid #686868;
      height: fit-content;
      line-height: 18px;
      font-weight: 400;
    }

    .centre-column {
      text-align: left;
      flex: 6;
    }
  }

  .divider {
    border-top: 1px solid white;
    margin: 20px 0;
  }

  .copyright {
    text-align: left;
    margin-top: 10px;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;

    &:hover {
      font-weight: 500;
      color: #ddd;
    }
  }


  h6 {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: 'Helvetica';
    font-size: 12px;
  }

  .fr-view {
    text-align: left;
    padding-left: 8px;
  }
}
@media (max-width: 1024px){
  .footer {
  
    .footer-content {
       .centre-column {
        flex: 1.5;
        
      }
    }
  }

}
@media (max-width: 700px) {
  .footer {

    padding: 10px;
    .fr-view {
      padding-left: 0px;
    }
    .footer-content {
      flex-direction: column;

      .right-column {
        border-left: 0px solid #686868;
      }
    }
  }
}