@import '../../variables.scss';

.loader-container {
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  >span {
    margin: auto;
  }
}

  